// 高德地图
export default function MapLoader() {
    return new Promise((resolve, reject) => {
        if (window.AMap) {
            resolve(window.AMap)
        } else {
            var script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = true
            script.src =
                'https://webapi.amap.com/maps?v=1.4.11&callback=initAMap&key=f1654b4b9f444ef2a1f4ad6d54c02d1c'
            script.onerror = reject
            document.head.appendChild(script)
        }
        window.initAMap = () => {
            resolve(window.AMap)
        }
    })
}