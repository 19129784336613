<template>
	<v-container>
		<v-sheet rounded="lg" class="px-12">
			<base-section>
				<base-section-heading :title="$t('contactUs')">
				</base-section-heading>

				<v-row align="center" justify="center">
					<v-col cols="6">
						<v-card rounded="lg" elevation="0">
							<div
								id="container"
								style="height: 400px"
								class="w-100"
							></div>
						</v-card>
					</v-col>
					<v-col cols="6">
						<p>
							<v-icon color="primary" class="mr-2"
								>mdi-phone-classic</v-icon
							>
							010-67080760
						</p>
						<p>
							<v-icon color="primary" class="mr-2"
								>mdi-fax</v-icon
							>
							010-67089760
						</p>
						<p>
							<v-icon color="primary" class="mr-2"
								>mdi-email</v-icon
							>
							apply@sdchina.org.cn
						</p>
						<p>
							<v-icon color="primary" class="mr-2"
								>mdi-web</v-icon
							>
							https://sdchina.org.cn
						</p>
						<p>
							<v-icon color="primary" class="mr-2"
								>mdi-map-marker</v-icon
							>
							{{ $t("address") }}
						</p>
					</v-col>
				</v-row>
			</base-section>
		</v-sheet>
	</v-container>
</template>

<script>
import MapLoader from "@/plugins/amap.js";
export default {
	name: "Contact",

	data() {
		return {
			map: null,
		};
	},
	mounted() {
		MapLoader().then(
			(AMap) => {
				console.log("地图加载成功");
				this.map = new AMap.Map("container", {
					mapStyle: "amap://styles/whitesmoke", //normal, dark, light, whitesmoke, fresh, grey, graffiti, macaron, blue, darkblue, wine
					center: [116.418187, 39.894171],
					resizeEnable: true,
					zoom: 12,
				});
			},
			(e) => {
				console.log("地图加载失败", e);
			}
		);
	},
	methods: {},
};
</script>